import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ReactInputMask from 'react-input-mask'

function InputMask({onChange, ...others}) {
    const [mask, setMask] = useState('')

    const handleChange = (e) => {
        const {value} = e.target

        if (value.length) {
            if (/[0-9]/.test(value)) {
                if (/^[+7]/.test(value)) {
                    setMask('+9(999)999-9999')
                    onChange(value)
                } else if (/^8/.test(value)) {
                    setMask('9(999)999-9999')
                    onChange(value)
                } else if (/^[1-9]/.test(value)) {
                    setMask('+9(999)999-9999')
                    onChange(`7${value}`)
                } else {
                    setMask('')
                    onChange(value)
                }
            } else if (!/\+(?=.+\d)/.test(value) && value === '+') {
                setMask('')
                onChange(value)
            } else if (/\+7(?=.+\d)/.test(value)) {
                setMask('+9(999)999-9999')
                onChange(value)
            } else {
                setMask('')
                onChange('')
            }
        } else {
            setMask('')
            onChange('')
        }
    }
    return (
        <ReactInputMask
            {...others}
            mask={mask}
            onChange={handleChange}
            type="tel"
        />
    )
}

InputMask.propTypes = {
    onChange: PropTypes.func,
}

export default InputMask
